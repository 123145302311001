import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import preformatedMetal01 from "../images/preformated-metal-01.jpg"
import preformatedMetal02 from "../images/preformated-metal-02.jpg"
import { Container, Image } from "react-bootstrap"

export default () => (
  <Layout>
    <SEO title="Perforated Metal" />
    <Container className="singleCol">
      <h1>Perforated Metal</h1>
      <p>
        Perforated Metal comes in many hole sizes, shapes, gauges and material types. Whether your application is acoustical, automotive, guarding/ventilating, light construction, separating/sizing, cleaning, straining, filtering or requires aesthetically appealing looks, Howard Wire Cloth has it!
      </p>
      <p>
        For full details and specifications: Please call us at (510) 887-8787
      </p>
      <p>
      For more information, visit: <a href="http://howardwire.com/perforated_metals.html">http://howardwire.com/perforated_metals.html</a>.
      </p>
      <p>
        <Image src={preformatedMetal01} className="ml-2 mr-2" alt="Preformated Metal" fluid />
        <Image src={preformatedMetal02} className="ml-2 mr-2" alt="Preformated Metal" fluid />
      </p>
    </Container>
  </Layout>
)
